
import { Vue, Component, Watch } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'
import auth from '@/store/modules/auth'
import CharterUPLogo from '@/icons/CharterUPLogo.vue'
import { SplitFeatureFlag } from '@/utils/enum'
import Auth0AppState from '@/models/dto/Auth0AppState'
import { getTokenProvider, TokenProvider } from '@/services/authTokenRepository'
import RouteConfigWithMeta from '@/models/RouteConfig'
import user from '@/store/modules/user'
import { getSupportNumber, phoneFormatFilterHyphens } from '@/utils/phone'
import systemParameter from '@/store/modules/systemParameter'

@Component({
  name: 'Auth0Callback',
  components: {
    CharterUPLogo,
  },
})
export default class Auth0Callback extends Vue {
  authHandled = false
  hasError: boolean = false
  isAuth0Enabled: boolean = false
  appState: Auth0AppState | null = null
  isRequiredCustomerInfoModalOpen: boolean = false

  // TODO: ONCE AUTH0 IS ENABLED, WE CAN REMOVE THIS ENTIRE MOUNTED FUNCTION - Erik 1/2/25
  async mounted(): Promise<void> {
    this.isAuth0Enabled = await this.$split.isFeatureEnabled(SplitFeatureFlag.Auth0SignIn)
    // this is a workaround for handling users coming back from Auth0 login using the Auth0 override link
    if (!this.isAuth0Enabled && !this.$auth0.isInitialized) {
      await this.$auth0.init()
    }
  }

  @Watch('$auth0.loading', { immediate: true })
  onAuth0LoadingChanged(loading: boolean) {
    if (loading) {
      return
    }
    if (!this.authHandled) {
      this.handleRedirectCallback()
    }
  }

  get rawPhone(): string {
    return getSupportNumber(false)
  }

  get formattedPhone(): string {
    return phoneFormatFilterHyphens(this.rawPhone)
  }

  get supportEmail(): string {
    return systemParameter.supportEmail
  }

  async handleRedirectCallback() {
    this.hasError = false
    this.authHandled = true // Ensure we don't handle the callback more than once
    try {
      await this.resolveAppState()
      await this.resolveToken()
    } catch (error) {
      const currentTokenProvider = getTokenProvider()
      if (currentTokenProvider === TokenProvider.Auth0) {
        // This could be an accidental back button press, redirect to the home page.
        // If the user's token is not valid or expired, they will be redirected to the login page by our route guards.
        this.goToRedirect()
      } else {
        this.handleError(error)
      }
    }

    try {
      await user.fetchRequiredInformation()
      if (user.isExistingCustomer) {
        await auth.jwtLogin()
        this.trackLogin()
      }
      // if they don't have an existing account, we can't log them in
      // but on the redirect, they will be prompted to finish signing up
      this.goToRedirect()
    } catch (error) {
      this.handleError(error)
    }
  }

  async resolveAppState(): Promise<void> {
    const appState = await this.$auth0.handleRedirectCallback()
    if (appState === undefined) { // User was not redirected from Auth0 login
      throw new Error('An error occured. Please try again.')
    }
    this.appState = appState
  }

  async resolveToken(): Promise<void> {
    const accessToken = await this.$auth0.getTokenSilently()
    auth.setToken({token: accessToken, legacy: false })
  }

  buildRedirect(): RawLocation {
    const { redirectFrom, routeName = 'home', query } = this.appState || {}
    if (redirectFrom) {
      return redirectFrom as RawLocation
    }

    const route: Record<string, any> = { name: routeName }
    if (query) {
      route.query = query
    }
    return route
  }

  goToRedirect(): void {
    const redirect = this.buildRedirect()
    this.$router.push(redirect)
  }

  resolveRoute(): RouteConfigWithMeta {
    const redirect = this.buildRedirect()
    const resolvedRoute = this.$router.resolve(redirect)
    const route = resolvedRoute.route
    return route as unknown as RouteConfigWithMeta
  }

  handleError(error: any): void {
    this.hasError = true
    console.error('Error handling redirect:', error)
  }

  async logout() {
    await auth.logout()
  }

  trackLogin(): void {
    this.$ga4Event('login', {
      isAuth0: true,
    })
  }

}
